<template>
  <card type="default" header-classes="bg-transparent" class="checklist-item-card" v-loading="loader">
    <slot name="header"></slot>
    <el-table-draggable
      handle=".handle"
      @drop="updateSortList"
    >
    <el-table
      class="table-responsive table-flush checklist-items-table checklist-template-item-card"
      header-row-class-name="thead-light"
      :show-header="showHeaders"
      :data="response.list"
    >
      <el-table-column align="left" :class-name="btnDraggable" width="50">
        <template slot-scope="scope">
          <div class="handle" v-if="scope.row.id !==''">
            <img src="/img/icons/buttons/handle.svg" alt="+">
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(column, index) in tableColumns"
        :key="column.label"
        v-bind="column"
        :class-name="column.prop"
      >
        <template scope="scope">
          <el-form :model="response" ref="checklistForm" size="mini">
            <div v-if="column.prop =='days_to_complete'">
              <el-form-item
                :prop="`list.${scope.$index}.${column.prop}`"
                :key="column.label"
              >
                <el-input @change="validateNumber(scope)" type="number" :min="1" v-model="scope.row[column.prop]" @focus="addToUpdateList(scope.row)"/>
              </el-form-item>
            </div>
            <div v-else-if="column.prop =='description' || column.prop =='name'">
              <el-form-item
                :prop="`list.${scope.$index}.${column.prop}`"
                :key="column.label"
                :rules="{ required: true, message: 'Please input the field', trigger: 'blur' }"
              >
                <el-select v-if="showTemplateItemsDropdown"
                  class="templates-checklist-dropdown w-100"
                  v-model="scope.row[column.prop]"
                  remote
                  filterable
                  :allow-create="true"
                  :remote-method="query => getFilteredChecklistItems(query, scope.row)"
                  placeholder=""
                  :loading="loaders.checklistItemsFilter">
                  <el-option
                    v-for="item in filteredChecklistItems"
                    :value="item.description"
                    :label="item.description"
                    :key="item.id">
                  </el-option>
                </el-select>
                <el-input v-else v-model="scope.row[column.prop]" @change="addToUpdateList(scope.row)"/>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column min-width="60px" align="right">
        <div slot-scope="scope" class="d-flex">
          <div class="">
            <el-tooltip v-if="showPatientCheckIcon"
                        popper-class="tooltip-popper-class-width-400"
                        class="item" effect="dark"
                        :content="'One checkmark indicates the patient cannot check this item off. ' +
                     'When both checkmarks are checked both you and the patient can check off the item.'"
                        placement="bottom-start"
                        :visible-arrow="false"
            >
              <img class="pointer mr-1"
                   :src="getImageSource(scope.row['patient_can_mark'])" alt=""
                   @click="updatePatientCanMark(scope, 'patient_can_mark')">
            </el-tooltip>
          </div>
          <div v-for="(action, index) in actions" :key="index" class="float-right">
            <template v-if="showHeaders">
              <el-tooltip
                class="item" effect="dark"
                :content="'You can add additional details here that your patient can see when they click on this item in their checklist'"
                placement="bottom-start"
                :visible-arrow="false"
              >
                <img class="pointer mr-2" src="/img/icons/buttons/detail.svg" alt="" @click="openDetailModal(scope.$index, scope.row)">
              </el-tooltip>
            </template>
            <el-tooltip
              :disabled="hideToolTip"
              class="item" effect="dark"
              :content="'Delete this item from the checklist'"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <actions
                class="pointer mr-2 float-right"
                @handleDelete="handleDelete(scope.$index, scope.row)"
                :action="action.action"
                :src="action.src"
                alt=""
              />
            </el-tooltip>
          </div>
        </div>
      </el-table-column>
    </el-table>
    </el-table-draggable>
    <div class="mt-3">
      <base-button
        type="secondary"
        class="pt-1 pb-1 pl-1 pr-3 font-weight-normal add-checklist-buttons"
        id="add-row"
        @click="addChecklistItemRow">
        <img src="/img/icons/buttons/plus.svg" alt="+">
        Add Row
      </base-button>
      <slot name="addChecklistItem"></slot>
    </div>
  </card>
</template>

<script>
import {Table, TableColumn, Select, Option} from "element-ui";
import Actions from "@/components/Actions/Actions";
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
  name: "ChecklistItemCard",
  props: ['itemsList', 'loader', 'tableColumns', 'actions', 'rules', 'showHeaders', 'templateItems', 'isTemplate', 'hideToolTip', 'showPatientCheckIcon'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Actions,
    ElTableDraggable,
  },
  data() {
    return {
      response: {
        list: [],
      },
      btnDraggable:'btnDraggable',
      loaders: {
        checklistItemsFilter: false,
      },
      filteredChecklistItems: [],
      otherTemplateItems: [],
      showTemplateItemsDropdown: false,
    }
  },
  watch: {
    isTemplate(newVal) {
      this.showTemplateItemsDropdown = newVal
    },

    templateItems(newVal) {
      this.otherTemplateItems = newVal
    },

    itemsList(newValue) {
      this.response.list = newValue;
    }
  },
  methods: {
    /**
     * Check number must be greater than 0
     * @param scope
     */
    validateNumber(scope) {
      if (this.response.list[scope.$index]['days_to_complete'] <= 0) {
        this.response.list[scope.$index]['days_to_complete'] = null
      }
    },

    /**
     * Update patient can mark check for checklist item
     * @param scope
     * @param prop
     */
    updatePatientCanMark(scope, prop) {
      this.response.list[scope.$index][prop] = !this.response.list[scope.$index][prop]
      this.addToUpdateList(scope.row)
    },

    /**
     * Get image source for patient can check
     * @param prop
     * @returns {string}
     */
    getImageSource(prop) {
      return prop == true ? '/img/green_only.svg' : '/img/green_gray.svg';
    },

    getFilteredChecklistItems(query) {
      let vm  = this
      if (query !== '') {
        vm.loaders.checklistItemsFilter = true
        setTimeout(() => {
          vm.filteredChecklistItems = vm.otherTemplateItems.filter(item => {
            return item.description.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
          vm.loaders.checklistItemsFilter = false

          //Extracting unique values from array based on description
          vm.filteredChecklistItems = [...new Map(vm.filteredChecklistItems.map(item => [item['description'], item])).values()]
        }, 200)
      } else {
        vm.filteredChecklistItems = []
      }
    },

    addToUpdateList(element) {
      this.$emit('addToUpdateList', element)
    },
    handleDelete(index, element) {
      this.$emit('handleDelete', {index, element})
    },
    addChecklistItemRow() {
      this.$emit('addChecklistItemRow');
    },
    openDetailModal(index, row) {
      this.$emit('openChecklistModal', {index, row});
    },
    updateSortList(evt){
      //console.log(evt);
      this.response.list = evt.list;
    },
  }
}
</script>

<style>

.checklist-items-container {
  border: none !important;
  border-bottom: 1px solid rgba(215, 208, 208, 0.26) !important;
}

.checklist-item-card .card-body .el-form-item--mini.el-form-item,
.checklist-item-card .card-body .el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}

.checklist-items-table .el-table__row .description{
  padding-left: 0px;
  margin-left: 0px;
}


.btnDraggable {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  cursor: grabbing;
  margin-left: 0px;
}

div.checklist-template-item-card > .el-table__body-wrapper > .el-table__body > tbody > .el-table__row:hover {
  background-color: #dbdde1 !important;
}

div.templates-checklist-dropdown > .el-input > .el-input__inner {
  height: 29px;
  font-size: 12px;
}

</style>
